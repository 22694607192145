import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Deadlifts 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Test 1: Max HSPU’s Strict (1 attempt)`}</p>
    <p>{`Test 2: Max HSPU’s Kipping (1 attempt)`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Dianne”`}</strong></p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Deadlifts (225/155)`}</p>
    <p>{`HSPU’s`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This coming Tuesday is the 4th of July.  We will have our free
holiday class at The Ville at 10:00am.  This will be our only class on
the 4th, all other classes and open gym will be cancelled so come out
and join in the fun!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      